import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  Input,
  InputGroup,
  InputGroupRadio,
  InputGroupSelectRange,
  InputMultiSelect
  // InputGroupYearRange
} from '../common';
import InputSelect from '../common/InputSelect';

import AbilympicsProfessions from '../../data/AbilympicsProfession';
import {
  bestApprentice,
  genderOptions,
  ouvrierOption,
  fullRangeGroup
} from '../../data/professionOptions';

const GenericField = ({
  languages = [],
  fieldKey,
  requiredFieldMissing = false,
  formik,
  regions,
  professions,
  listSwissRegions,
  listProfessionalAssoc,
  listSwissFunction,
  listSwissJobTitle,
  listVocationalSchool,
  orgPrefix
}) => {
  const { t } = useTranslation();

  const [updatedGenderOptions, setUpdatedGenderOptions] = useState(...genderOptions);
  const [updatedBestApprenticeOptions, setUpdatedBestApprenticeOptions] = useState([
    ...bestApprentice
  ]);
  const [updatedOuvrierOptions, setOuvrierOptions] = useState([...ouvrierOption]);
  const [fieldTranslation, setFieldTranslation] = useState({
    label: '',
    placeholder: '',
    error: ''
  });
  const [rankFieldTranslation, setRankFieldTranslation] = useState({ label: '', placeholder: '' });
  // const [yearFieldTranslation, setYearFieldTranslation] = useState({ label: '', placeholder: '' });

  useEffect(() => {
    const tGenders = t('form.genderOptions', { returnObjects: true });

    setUpdatedGenderOptions([
      {
        label: tGenders['masculin']?.[orgPrefix] ?? tGenders['masculin']?.default,
        value: 'masculin'
      },
      {
        label: tGenders['féminin']?.[orgPrefix] ?? tGenders['féminin']?.default,
        value: 'féminin'
      }
    ]);

    const tBestApprentice = t('form.bestApprenticeOptions', { returnObjects: true });

    setUpdatedBestApprenticeOptions([
      {
        label: tBestApprentice['oui']?.[orgPrefix] ?? tBestApprentice['oui']?.default,
        value: 'oui'
      },
      {
        label: tBestApprentice['no']?.[orgPrefix] ?? tBestApprentice['no']?.default,
        value: 'no'
      }
    ]);

    const tOuvrier = t('form.ouvrierOptions', { returnObjects: true });

    setOuvrierOptions([
      {
        label: tOuvrier['yes']?.[orgPrefix] ?? tOuvrier['yes']?.default,
        value: 'yes'
      },
      {
        label: tOuvrier['no']?.[orgPrefix] ?? tOuvrier['no']?.default,
        value: 'no'
      }
    ]);

    const tFieldTranslation = t(`form.fields.${fieldKey}`, { returnObjects: true });
    setFieldTranslation(
      tFieldTranslation?.[orgPrefix]
        ? tFieldTranslation[orgPrefix]
        : tFieldTranslation?.default
        ? tFieldTranslation.default
        : { label: fieldKey, placeholder: '', error: '' }
    );

    const tRankFieldTranslation = t(`form.fields.rankField`, { returnObjects: true });
    setRankFieldTranslation(
      tRankFieldTranslation?.[orgPrefix]
        ? tRankFieldTranslation[orgPrefix]
        : tRankFieldTranslation.default
    );
  }, [fieldKey, orgPrefix]);

  return (
    <>
      {fieldKey === 'firstName' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="firstName"
          labelClassName="font-bold mb-1"
          value={formik.values.firstName}
          touched={formik.touched.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.firstName}
        />
      )}
      {fieldKey === 'familyName' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="familyName"
          labelClassName="font-bold mb-1"
          value={formik.values.familyName}
          touched={formik.touched.familyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.familyName}
        />
      )}
      {fieldKey === 'birthYear' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="birthYear"
          type="number"
          labelClassName="font-bold mb-1"
          value={formik.values.birthYear}
          touched={formik.touched.birthYear}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.birthYear}
        />
      )}
      {fieldKey === 'birthday' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-1"
          name="birthday"
          type="date"
          labelClassName={'font-semibold'}
          value={
            formik.values.birthday?.includes('T')
              ? formik.values.birthday.split('T')?.[0]
              : formik.values.birthday
          }
          touched={formik.touched.birthday}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.birthday}
        />
      )}
      {fieldKey === 'postalCode' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-1"
          name="postalCode"
          type="number"
          value={formik.values.postalCode}
          touched={formik.touched.postalCode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.postalCode}
        />
      )}
      {fieldKey === 'postcode' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="postcode"
          type="number"
          labelClassName="font-bold mb-1"
          value={formik.values.postcode}
          touched={formik.touched.postcode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.postcode}
        />
      )}
      {fieldKey === 'city' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="city"
          labelClassName="font-bold mb-1"
          value={formik.values.city}
          touched={formik.touched.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.city}
        />
      )}
      {fieldKey === 'abilympicsProfession' && (
        <InputSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="abilympicsProfession"
          labelClassName="font-bold mb-1"
          value={formik.values.abilympicsProfession}
          touched={formik.touched.abilympicsProfession}
          onBlur={formik.handleBlur}
          error={formik.errors.abilympicsProfession}
          options={AbilympicsProfessions}
          onChange={(val) => {
            formik.setFieldValue('abilympicsProfession', val, true);
            setTimeout(() => formik.setFieldTouched('abilympicsProfession', true));
          }}
        />
      )}
      {fieldKey === 'gender' && (
        <InputGroupRadio
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="gender"
          labelClassName="font-bold mb-1"
          value={formik.values.gender}
          touched={formik.touched.gender}
          onBlur={formik.handleBlur}
          error={formik.errors.gender}
          setFieldValue={formik.setFieldValue}
          options={updatedGenderOptions}
        />
      )}
      {fieldKey === 'ouvrier' && (
        <InputGroupRadio
          label={fieldTranslation?.label}
          labelClassName={'font-semibold'}
          className="my-1"
          name="ouvrier"
          value={formik.values.ouvrier}
          touched={formik.touched.ouvrier}
          onBlur={formik.handleBlur}
          error={formik.errors.ouvrier}
          setFieldValue={formik.setFieldValue}
          options={updatedOuvrierOptions}
        />
      )}
      {fieldKey === 'euroSkillsYear' && (
        <InputGroupSelectRange
          label={fieldTranslation?.label}
          year={{
            name: 'euroSkillsYear',
            type: 'number',
            value: formik.values.euroSkillsYear,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            placeholder: fieldTranslation?.placeholder,
            error: formik.errors.euroSkillsYear,
            touched: formik.touched.euroSkillsYear
          }}
          range={{
            label: rankFieldTranslation?.label,
            placeholder: rankFieldTranslation?.placeholder,
            type: 'select',
            name: 'euroSkillsRank',
            // onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: formik.errors.euroSkillsRank,
            touched: formik.touched.euroSkillsRank,
            setFieldValue: formik.setFieldValue,
            options: fullRangeGroup,
            value: formik.values.euroSkillsRank
          }}
          labelFirst={true}
          onReset={() => {
            formik.setFieldValue('euroSkillsYear', '', false);
            formik.setFieldValue('euroSkillsRank', '', false);
          }}
        />
      )}
      {fieldKey === 'worldSkillsYear' && (
        <InputGroupSelectRange
          label={fieldTranslation?.label}
          labelClassName="font-bold mb-1"
          year={{
            name: 'worldSkillsYear',
            type: 'number',
            value: formik.values.worldSkillsYear,
            placeholder: fieldTranslation?.placeholder,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: formik.errors.worldSkillsYear,
            touched: formik.touched.worldSkillsYear
          }}
          range={{
            label: rankFieldTranslation?.label,
            placeholder: rankFieldTranslation?.placeholder,
            type: 'select',
            name: 'worldSkillsRank',
            touched: formik.touched.worldSkillsRank,
            value: formik.values.worldSkillsRank,
            onBlur: formik.handleBlur,
            error: formik.errors.worldSkillsRank,
            setFieldValue: formik.setFieldValue,
            options: fullRangeGroup
          }}
          labelFirst={true}
          onReset={() => {
            formik.setFieldValue('worldSkillsYear', '', false);
            formik.setFieldValue('worldSkillsRank', '', false);
          }}
        />
      )}
      {fieldKey === 'worldSkillsProfession' && (
        <InputSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-1"
          name="worldSkillsProfession"
          labelClassName={'font-semibold'}
          value={formik.values.worldSkillsProfession}
          touched={formik.touched.worldSkillsProfession}
          onBlur={formik.handleBlur}
          error={formik.errors.worldSkillsProfession}
          options={professions}
          onChange={(val) => {
            formik.setFieldValue('worldSkillsProfession', val, true);
            setTimeout(() => formik.setFieldTouched('worldSkillsProfession', true));
          }}
        />
      )}
      {fieldKey === 'natFinalsRegion' && (
        <InputSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="natFinalsRegion"
          labelClassName="font-bold mb-1"
          value={formik.values.natFinalsRegion}
          touched={formik.touched.natFinalsRegion}
          onBlur={formik.handleBlur}
          error={formik.errors.natFinalsRegion}
          options={regions}
          onChange={(val) => {
            formik.setFieldValue('natFinalsRegion', val, true);
            setTimeout(() => formik.setFieldTouched('natFinalsRegion', true));
          }}
        />
      )}
      {fieldKey === 'region' && (
        <InputSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          labelClassName={'font-semibold'}
          className="my-1"
          name="region"
          value={formik.values.region}
          touched={formik.touched.region}
          onBlur={formik.handleBlur}
          error={formik.errors.region}
          options={listSwissRegions}
          onChange={(val) => {
            formik.setFieldValue('region', val, true);
            setTimeout(() => formik.setFieldTouched('region', true));
          }}
        />
      )}
      {fieldKey === 'mobileNumber' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-1"
          name="mobileNumber"
          labelClassName={'font-semibold'}
          value={formik.values.mobileNumber}
          touched={formik.touched.mobileNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.mobileNumber}
        />
      )}
      {fieldKey === 'montherLanguage' && (
        <InputSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          labelClassName={'font-semibold'}
          className="my-1"
          name="montherLanguage"
          value={formik.values.montherLanguage}
          touched={formik.touched.montherLanguage}
          onBlur={formik.handleBlur}
          error={formik.errors.montherLanguage}
          options={languages.map((l) => ({ value: l, label: l?.toUpperCase() }))}
          onChange={(val) => {
            formik.setFieldValue('montherLanguage', val, true);
            setTimeout(() => formik.setFieldTouched('montherLanguage', true));
          }}
        />
      )}
      {fieldKey === 'otherLanguage' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-1"
          name="otherLanguage"
          labelClassName={'font-semibold'}
          value={formik.values.otherLanguage}
          touched={formik.touched.otherLanguage}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.otherLanguage}
        />
      )}
      {fieldKey === 'specialJobTitle' && (
        <InputSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          labelClassName={'font-semibold'}
          className="my-1"
          name="specialJobTitle"
          value={formik.values.specialJobTitle}
          touched={formik.touched.specialJobTitle}
          onBlur={formik.handleBlur}
          error={formik.errors.specialJobTitle}
          options={listSwissJobTitle}
          onChange={(val) => {
            formik.setFieldValue('specialJobTitle', val, true);
            setTimeout(() => formik.setFieldTouched('specialJobTitle', true));
          }}
        />
      )}
      {fieldKey === 'profession' && (
        <InputSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          labelClassName={'font-semibold'}
          className="my-1"
          name="profession"
          value={formik.values.profession}
          touched={formik.touched.profession}
          onBlur={formik.handleBlur}
          error={formik.errors.profession}
          options={professions}
          profiessionPlaceholder
          onChange={(val) => {
            formik.setFieldValue('profession', val, true);
            setTimeout(() => formik.setFieldTouched('profession', true));
          }}
        />
      )}
      {fieldKey === 'swissChampionYear' && (
        <InputGroupSelectRange
          label={fieldTranslation?.label}
          labelClassName={'font-semibold'}
          setFieldValue={formik.setFieldValue}
          year={{
            name: 'swissChampionYear',
            type: 'number',
            value: formik.values.swissChampionYear,
            placeholder: fieldTranslation?.placeholder,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: formik.errors.swissChampionYear,
            touched: formik.touched.swissChampionYear
          }}
          range={{
            name: 'swissChampionRank',
            label: rankFieldTranslation?.label,
            placeholder: rankFieldTranslation?.placeholder,
            type: 'select',
            onChange: formik,
            onBlur: formik.handleBlur,
            error: formik.errors.swissChampionRank,
            value: formik.values.swissChampionRank,
            touched: formik.touched.swissChampionRank,
            setFieldValue: formik.setFieldValue,
            options: fullRangeGroup
          }}
          labelFirst={true}
          onReset={() => {
            formik.setFieldValue('swissChampionYear', '', false);
            formik.setFieldValue('swissChampionRank', '', false);
          }}
        />
      )}
      {fieldKey === 'currentCompanyCityDept' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="currentCompanyCityDept"
          labelClassName="font-bold mb-1"
          value={formik.values.currentCompanyCityDept}
          touched={formik.touched.currentCompanyCityDept}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.currentCompanyCityDept}
        />
      )}
      {fieldKey === 'traningCopmanyCityDept' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="traningCopmanyCityDept"
          labelClassName="font-bold mb-1"
          value={formik.values.traningCopmanyCityDept}
          touched={formik.touched.traningCopmanyCityDept}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.traningCopmanyCityDept}
        />
      )}
      {fieldKey === 'bestApprentice' && (
        <InputGroupRadio
          label={fieldTranslation?.label}
          className="my-2"
          name="bestApprentice"
          labelClassName="font-bold mb-1"
          value={formik.values.bestApprentice}
          touched={formik.touched.bestApprentice}
          // onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.bestApprentice}
          setFieldValue={formik.setFieldValue}
          options={updatedBestApprenticeOptions}
        />
      )}
      {fieldKey === 'bestWorker' && (
        <InputGroupRadio
          label={fieldTranslation?.label}
          className="my-22"
          name="bestWorker"
          labelClassName="font-bold mb-1"
          value={formik.values.bestWorker}
          touched={formik.touched.bestWorker}
          // onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.bestWorker}
          setFieldValue={formik.setFieldValue}
          options={bestApprentice}
        />
      )}
      {fieldKey === 'natFinalsYear' && (
        <InputGroupSelectRange
          label={fieldTranslation?.label}
          labelClassName="font-bold mb-1"
          year={{
            name: 'natFinalsYear',
            type: 'number',
            value: formik.values.natFinalsYear,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            placeholder: fieldTranslation?.placeholder,
            error: formik.errors.natFinalsYear,
            touched: formik.touched.natFinalsYear
          }}
          range={{
            label: rankFieldTranslation?.label,
            placeholder: rankFieldTranslation?.placeholder,
            name: 'natFinalsRank',
            type: 'select',
            onBlur: formik.handleBlur,
            error: formik.errors.natFinalsRank,
            touched: formik.touched.natFinalsRank,
            setFieldValue: formik.setFieldValue,
            options: fullRangeGroup,
            value: formik.values.natFinalsRank
          }}
          labelFirst={true}
          onReset={() => {
            formik.setFieldValue('natFinalsYear', '', false);
            formik.setFieldValue('natFinalsRank', '', false);
          }}
        />
      )}
      {fieldKey === 'trainingAndDiploma' && (
        <InputGroup
          label={fieldTranslation?.label}
          labelClassName="font-bold mb-1"
          fields={[
            {
              name: 'trainingAndDiploma1',
              value: formik.values.trainingAndDiploma1,
              touched: formik.touched.trainingAndDiploma1,
              onChange: (e) => {
                formik.setFieldValue('trainingAndDiploma', e.target.value, true);
                formik.handleChange(e);
              },
              onBlur: formik.handleBlur,
              error: formik.errors.trainingAndDiploma1,
              max: 90,
              placeholder: fieldTranslation?.placeholder
            },
            {
              name: 'trainingAndDiploma2',
              value: formik.values.trainingAndDiploma2,
              touched: formik.touched.trainingAndDiploma2,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: formik.errors.trainingAndDiploma2,
              placeholder: fieldTranslation?.placeholder
            },
            {
              name: 'trainingAndDiploma3',
              value: formik.values.trainingAndDiploma3,
              touched: formik.touched.trainingAndDiploma3,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: formik.errors.trainingAndDiploma3,
              placeholder: fieldTranslation?.placeholder
            }
          ]}
        />
      )}
      {fieldKey === 'currentJobName' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="currentJobName"
          labelClassName="font-bold mb-1"
          value={formik.values.currentJobName}
          touched={formik.touched.currentJobName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.currentJobName}
        />
      )}
      {fieldKey === 'linkedinAddress' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-1"
          name="linkedinAddress"
          labelClassName={'font-semibold'}
          value={formik.values.linkedinAddress}
          touched={formik.touched.linkedinAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.linkedinAddress}
        />
      )}
      {fieldKey === 'instagramAddress' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="instagramAddress"
          labelClassName="font-bold mb-1"
          value={formik.values.instagramAddress}
          touched={formik.touched.instagramAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.instagramAddress}
        />
      )}
      {fieldKey === 'tiktokAddress' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="tiktokAddress"
          labelClassName="font-bold mb-1"
          value={formik.values.tiktokAddress}
          touched={formik.touched.tiktokAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.tiktokAddress}
        />
      )}
      {fieldKey === 'facebookAddress' && (
        <Input
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          className="my-2"
          name="facebookAddress"
          labelClassName="font-bold mb-1"
          value={formik.values.facebookAddress}
          touched={formik.touched.facebookAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.facebookAddress}
        />
      )}
      {fieldKey === 'associationProfession' && (
        <InputSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          labelClassName={'font-semibold'}
          className="my-1"
          name="associationProfession"
          value={formik.values.associationProfession}
          touched={formik.touched.associationProfession}
          onBlur={formik.handleBlur}
          error={formik.errors.associationProfession}
          options={listProfessionalAssoc}
          onChange={(val) => {
            formik.setFieldValue('associationProfession', val, true);
            setTimeout(() => formik.setFieldTouched('associationProfession', true));
          }}
        />
      )}
      {fieldKey === 'vocationSchool' && (
        <InputSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          labelClassName={'font-semibold'}
          className="my-1"
          name="vocationSchool"
          value={formik.values.vocationSchool}
          touched={formik.touched.vocationSchool}
          onBlur={formik.handleBlur}
          error={formik.errors.vocationSchool}
          options={listVocationalSchool}
          onChange={(val) => {
            formik.setFieldValue('vocationSchool', val, true);
            setTimeout(() => formik.setFieldTouched('vocationSchool', true));
          }}
        />
      )}
      {fieldKey === 'functions' && (
        <InputMultiSelect
          label={fieldTranslation?.label}
          placeholder={fieldTranslation?.placeholder}
          labelClassName={'font-semibold'}
          className="my-1"
          name="functions"
          value={formik.values.functions}
          touched={formik.touched.functions}
          onBlur={formik.handleBlur}
          error={formik.errors.functions}
          options={listSwissFunction}
          onChange={(val) => {
            formik.setFieldValue('functions', val, true);
            setTimeout(() => formik.setFieldTouched('functions', true));
          }}
        />
      )}
      {fieldKey === 'recentProfession' && (
        <InputGroup
          label={fieldTranslation?.label}
          labelClassName={'font-semibold'}
          fields={[
            {
              name: 'recentProfession1',
              value: formik.values.recentProfession1,
              touched: formik.touched.recentProfession1,
              onChange: (e) => {
                formik.setFieldValue('recentProfession', e.target.value, true);
                formik.handleChange(e);
              },
              onBlur: formik.handleBlur,
              error: formik.errors.recentProfession1,
              max: 90,
              placeholder: fieldTranslation?.placeholder
            },
            {
              name: 'recentProfession2',
              value: formik.values.recentProfession2,
              touched: formik.touched.recentProfession2,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: formik.errors.recentProfession2,
              placeholder: fieldTranslation?.placeholder
            },
            {
              name: 'recentProfession3',
              value: formik.values.recentProfession3,
              touched: formik.touched.recentProfession3,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: formik.errors.recentProfession3,
              placeholder: fieldTranslation?.placeholder
            }
          ]}
        />
      )}
      {fieldKey === 'participationType' && (
        <InputGroupRadio
          label={fieldTranslation?.label}
          // description={fieldTranslation.label}
          labelClassName={'font-semibold'}
          className="my-1"
          name="participationType"
          value={formik.values.participationType}
          touched={formik.touched.participationType}
          onBlur={formik.handleBlur}
          error={formik.errors.participationType}
          setFieldValue={formik.setFieldValue}
          // Special case for translation
          options={t('form.participationType.values', { returnObjects: true })}
          optionDirection="col"
        />
      )}
      {fieldKey === 'consent' && (
        <Checkbox
          label={fieldTranslation?.label}
          name="consent"
          className="checked:accent-chred-500 !w-6 !h-6 rounded-full mr-5 "
          checked={formik.values.consent}
          onChange={() => formik.setFieldValue('consent', !formik.values.consent)}
          touched={formik.touched.consent}
          error={formik.errors.consent}
          inOneLine={true}
        />
      )}

      {requiredFieldMissing && (
        <p className="text-red-500 text-base font-normal" data-testid="error-text">
          {fieldTranslation?.error ?? `form.fields.${fieldKey}.default.error`}
        </p>
      )}
    </>
  );
};

export default GenericField;
