import React from 'react';
import ListItem from './ListItem';
export default function ListSelector({
  list,
  listType,
  addListItem,
  removeListItem,
  selectedItems,
  maxItems
}) {
  return (
    <ul className="list-selector">
      {list.map((item) => {
        return (
          <ListItem
            key={item._id}
            data={item}
            listType={listType}
            addListItem={addListItem}
            removeListItem={removeListItem}
            selectedItems={selectedItems}
            maxItems={maxItems}
          />
        );
      })}
    </ul>
  );
}
