import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Partials from '../components/partials';
import Button from '../components/ui/button';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../contexts/ProfileContext';
import { requestValidation } from '../service';
import Swal from 'sweetalert2';
import { PreviewIcon } from '../components/icons';
import { Popups } from '../components';
import { CheckIfProfileIsComplete } from '../utils/utils';
import { getTranslation } from '../utils';
import { getProfileUrl } from '../utils/profilePreview';

export default function PgMyProfile() {
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [profileValidation, setProfileValidation] = useState(false);
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const [noProfilePreview, setNoProfilePreview] = useState(false);

  const [profile, setProfile] = useContext(ProfileContext);

  const {
    masterData: { translationPrefix, customConfigurations: { buttonRadius, colors } = {} } = {}
  } = profile;

  const exitHandler = () => {
    Swal.fire({
      title: getTranslation(t, `myProfile.logout.title`, translationPrefix),
      text: getTranslation(t, `myProfile.logout.description`, translationPrefix),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: getTranslation(t, `myProfile.logout.confirm`, translationPrefix),
      cancelButtonText: getTranslation(t, `myProfile.logout.cancel`, translationPrefix),
      iconColor: colors?.primary,
      confirmButtonColor: colors?.primary,
      cancelButtonColor: colors?.secondary,
      didOpen: () => {
        // Access the confirm and cancel buttons using Swal.getConfirmButton() and Swal.getCancelButton() or document.querySelector if necessary
        const confirmButton = Swal.getConfirmButton();
        const cancelButton = Swal.getCancelButton();
        // Set the border-radius directly
        if (confirmButton) confirmButton.style.borderRadius = buttonRadius;
        if (cancelButton) cancelButton.style.borderRadius = buttonRadius;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          localStorage.removeItem('lang');
          localStorage.removeItem('token');
        } catch (error) {
          console.log('LS Unavailable');
        } finally {
          navigate('/');
        }
      }
    });
  };

  const handleValidation = async () => {
    try {
      if (!CheckIfProfileIsComplete(profile)) {
        setIncompleteProfile(true);
        return;
      }

      if (profile.validationReduested) {
        setProfileValidation(true);
        return;
      }

      await requestValidation();
      setProfile({ ...profile, validationReduested: false });
      setProfileValidation(true);
    } catch (e) {
      if (e?.response?.status === 409) setProfileValidation(true);
      console.log('e', e.message);
    }
  };

  useEffect(() => {
    const isValidationRequested = searchParams.get('requestValidation');
    if (isValidationRequested && isValidationRequested === 'true') {
      handleValidation();
    }
  }, []);

  return (
    <section className="flex flex-col justify-between h-[80vh]">
      <Partials.MenuItems orgPrefix={translationPrefix} />
      <div className="mt-3">
        <div className="my-5 flex items-center justify-center">
          <a
            href={
              !CheckIfProfileIsComplete(profile)
                ? '#'
                : getProfileUrl(
                    profile?.profileTypeData?._id,
                    profile?._id,
                    i18n.language,
                    profile.validationReduested
                  )
            }
            target="_blank"
            className={`link-item ${!CheckIfProfileIsComplete(profile) && 'opacity-40'}`}
            rel="noreferrer"
            onClick={(e) => {
              if (!CheckIfProfileIsComplete(profile)) {
                e.preventDefault();
                setNoProfilePreview(true);
              }
            }}>
            <div className="title flex gap-2 items-center">
              <p
                dangerouslySetInnerHTML={{
                  __html: getTranslation(t, `myProfile.look`, translationPrefix)
                }}
              />
              <PreviewIcon />
            </div>
          </a>
        </div>

        <div className="my-5">
          <div className="flex">
            <Button.ButtonRounded
              className={`font-bold py-2 px-10 mx-auto disabled:opacity-50 disabled:cursor-not-allowed disabled:border disabled:border-[#757575] disabled:text-[#757575]`}
              disabled={profile.validationReduested}
              onClick={() => handleValidation()}>
              {getTranslation(t, `myProfile.validate`, translationPrefix)}
            </Button.ButtonRounded>
          </div>
        </div>

        <div className="flex">
          <button className="mt-5 mb-5 mx-auto" onClick={exitHandler}>
            {getTranslation(t, `myProfile.exitButton`, translationPrefix)}
          </button>
        </div>
      </div>

      {profileValidation && (
        <Popups.Validation
          orgPrefix={translationPrefix}
          close={() => setProfileValidation(false)}
        />
      )}

      {incompleteProfile && (
        <Popups.ProfileIncomplete
          orgPrefix={translationPrefix}
          close={() => setIncompleteProfile(false)}
        />
      )}

      {noProfilePreview && (
        <Popups.NoProfilePreview
          orgPrefix={translationPrefix}
          close={() => setNoProfilePreview(false)}
        />
      )}
    </section>
  );
}
