import Select from 'react-select';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export default function InputSelect({
  label,
  error,
  touched,
  labelClassName,
  options,
  onReset,
  ...props
}) {
  return (
    <div className="">
      {label && <label className={`text-lg ${labelClassName}`}>{label}</label>}

      {onReset && props.value && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}

      <Select
        styles={{
          control: (base) => ({
            ...base,
            background: '#f6f6f6',
            border: 0,
            boxShadow: 'none',
            padding: '0.5rem 0.5rem',
            borderRadius: '1rem',
            fontSize: '1.25rem',
            color: 'black',
            outline: 'none'
          }),
          option: (base) => ({
            ...base,
            color: 'black',
            fontSize: '1.25rem',
            padding: '0.5rem 0.5rem',
            cursor: 'pointer',
            backgroundColor: '#f6f6f6'
          })
        }}
        className="mb-4 border-none outline-none px-5 py-4 rounded-2xl text-xl bg-grey-100 text-black w-full"
        placeholder={label}
        options={options}
        {...props}
      />
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
