const userRoles = {
  CH_CHAMPION: '64a29767c6f7a0b95ed63b87',
  CH_NATIONAL_CHAMPION: '65645bfbb0640b80845244be',
  CH_AMBASSADOR: '6511227baaebee54ac41fb07',
  CHAMPION: '640f030be55aad73d8cb3dc7',
  TEST_CHAMPION: '651a8743a053d2754530966a',
  AMABASSADOR: '64101018f3cf9828e1f8bf3c',
  ABILYMPICS: '64101045b43b5e295ff259c4',
  COMPETITOR: ['6410104cb43b5e295ff259c5', '642eee5adb270f13745908a2', '642eee66db270f13745908a3'],
  SWISS_TYPE: ['64a29767c6f7a0b95ed63b87', '6511227baaebee54ac41fb07']
};

export const getProfileUrl = (profileType, profileId, lang, validationReduested) => {
  if (userRoles.COMPETITOR.includes(profileType)) {
    return `https://www.mission-future.com/competiteur-profile/${profileId}?lang=${lang}?from=profileView&isValidationRequested=${validationReduested}`;
  } else if (profileType === userRoles.CH_CHAMPION || profileType === userRoles.CH_AMBASSADOR) {
    return `https://www.mission-future.com/ch/champion-profile/${profileId}?lang=${lang}?from=profileView&isValidationRequested=${validationReduested}`;
  } else {
    return `https://www.mission-future.com/champion-profile/${profileId}?lang=${lang}?from=profileView&isValidationRequested=${validationReduested}`;
  }
};
