import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { FaRedo } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Popups from '../components/popups';
import Button from '../components/ui/button';
import { ProfileContext } from '../contexts/ProfileContext';
import { getRoles, getStrengths, postStrengthsAndRoles, storeStats } from '../service';
import { getSortedList, getTranslation } from '../utils';
import { DownArrowIcon } from '../components/icons';
import Loader from '../components/common/Loader';

export default function PgStrengths() {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const [selectedStrengths, setSelectedStrengths] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showStrengthsPopup, setShowStrengthsPopup] = useState(false);
  const [showRolesPopup, setShowRolesPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [strengths, setStrengths] = useState([]);

  const [profile, setProfileData] = useContext(ProfileContext);

  const { masterData: { translationPrefix } = {} } = profile;

  useEffect(() => {
    if (!profile?.loaded || !roles || !strengths) return;

    let tempStrengths = [],
      tempRoles = [];

    if (profile.strengths)
      tempStrengths = strengths.filter(({ _id }) => profile.strengths.includes(_id));
    if (profile.roles) tempRoles = roles.filter(({ _id }) => profile.roles.includes(_id));
    setSelectedRoles(tempRoles);
    setSelectedStrengths(tempStrengths);
  }, [profile, roles, strengths]);

  const getPageData = async () => {
    try {
      const currentLanguage = i18n.language;
      if (!profile?.loaded) return;
      const {
        data: { data: rolesData }
      } = await getRoles(profile.masterOrganization);
      const {
        data: { data: strengthData }
      } = await getStrengths(profile.masterOrganization);

      const sortedStrengths = getSortedList(strengthData, currentLanguage);
      const sortedRoles = getSortedList(rolesData, currentLanguage);

      setRoles(sortedRoles);
      setStrengths(sortedStrengths);
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setLoading(false);
    }
  };

  const stPopupHandler = () => {
    setShowStrengthsPopup(!showStrengthsPopup);
  };
  const rolePopupHandler = () => {
    setShowRolesPopup(!showRolesPopup);
  };
  const addListItem = (listItem, listType) => {
    if (listType === 'strengths') {
      if (strengths.find((item) => item.id === listItem.id)) {
        let data = [...selectedStrengths];
        data.push(listItem);
        setSelectedStrengths(data);
      }
    } else if (listType === 'roles') {
      if (roles.find((item) => item.id === listItem.id)) {
        let data = [...selectedRoles];
        data.push(listItem);
        setSelectedRoles(data);
      }
    }
  };
  const removeListItem = (listItem, listType) => {
    if (listType === 'strengths') {
      let itemindex = selectedStrengths.findIndex((item) => item.id === listItem.id);
      let data = [...selectedStrengths];
      data.splice(itemindex, 1);
      setSelectedStrengths(data);
    } else if (listType === 'roles') {
      let itemindex = selectedRoles.findIndex((item) => item.id === listItem.id);
      let data = [...selectedRoles];
      data.splice(itemindex, 1);
      setSelectedRoles(data);
    }
  };

  const nextHandler = async () => {
    if (selectedStrengths.length === 3 && selectedRoles.length === 3) {
      setErrorMessage('');
      setLoading(true);

      try {
        const filteredStrengths = selectedStrengths.map(({ _id }) => _id);
        const filteredRoles = selectedRoles.map(({ _id }) => _id);
        var body = {
          strengths: filteredStrengths,
          roles: filteredRoles
        };
        await postStrengthsAndRoles(body);

        try {
          for (let selectedStrength of selectedStrengths) {
            await storeStats({
              application: 'champion',
              event: 'champion_chosen_strength',
              orgPrefix: translationPrefix,
              pageURL: window?.location?.pathname,
              pageTitle: document?.title,
              championID: profile?._id,
              championName: `${profile?.firstName} ${profile?.familyName}`,
              strengthName: selectedStrength[`name_${i18n.language}`]
            });
          }
        } catch (error) {
          console.error('Error in storing stats for selected strengths');
        }

        try {
          for (let selectedRole of selectedRoles) {
            await storeStats({
              application: 'champion',
              event: 'champion_chosen_role',
              orgPrefix: translationPrefix,
              pageURL: window?.location?.pathname,
              pageTitle: document?.title,
              championID: profile?._id,
              championName: `${profile?.firstName} ${profile?.familyName}`,
              roleName: selectedRole[`name_${i18n.language}`]
            });
          }
        } catch (error) {
          console.error('Error in storing stats for selected roles');
        }

        setProfileData({
          ...profile,
          strengths: filteredStrengths,
          roles: filteredRoles,
          isUpdatedForPDF: true,
          validationReduested: false
        });

        navigate('/my-profile');
      } catch (error) {
        toast.error('Some error occured with updating the changes!');
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage('Select 3 roles and 3 strengths!');
    }
  };

  useEffect(() => {
    getPageData();
  }, [profile]);

  return (
    <>
      <Popups.FullPagePopup orgPrefix={translationPrefix}>
        <section className="pg-strengths">
          <div className="q mb-4">
            <div className="flex items-center justify-between w-full mb-8">
              <h1 className="text-2xl font-bold">
                {getTranslation(t, `strengthsRoles.title`, translationPrefix)}
              </h1>
              <Button.ButtonRounded onClick={nextHandler} loading={loading}>
                {getTranslation(t, `strengthsRoles.submit`, translationPrefix)}
              </Button.ButtonRounded>
            </div>
            <h1 className="text-xl font-bold mb-6">
              {getTranslation(t, `strengthsRoles.strengths`, translationPrefix)}
            </h1>
            <ul className="list-none list py-2 rounded">
              {selectedStrengths.map((strength, i) => {
                return (
                  <li
                    key={i}
                    className="cursor-pointer bg-grey-100 mb-3 rounded-full px-4 py-3 text-lg text-black font-bold flex items-center justify-between">
                    <span className="">{strength[`name_${i18n.language}`]}</span>
                    <span className="cursor-pointer" onClick={stPopupHandler}>
                      <FaRedo />
                    </span>
                  </li>
                );
              })}
              {Array.from(Array(3 - selectedStrengths.length).keys()).map((item, i) => (
                <li
                  key={i}
                  className="cursor-pointer bg-grey-100 mb-3 rounded-full px-4 py-3 text-lg text-black font-bold flex items-center justify-between"
                  onClick={stPopupHandler}>
                  {getTranslation(t, `strengthsRoles.strengthChoice`, translationPrefix)}
                  <DownArrowIcon />
                </li>
              ))}
            </ul>
          </div>
          <div className="q mb-4">
            <h1 className="text-xl font-bold mb-6">
              {getTranslation(t, `strengthsRoles.roles`, translationPrefix)}
            </h1>
            <ul className="list-none list py-2 rounded">
              {selectedRoles.map((role, i) => {
                return (
                  <li
                    key={i}
                    className="cursor-pointer bg-grey-100 mb-3 rounded-full px-4 py-3 text-lg text-black font-bold flex items-center justify-between">
                    <span className="">{role[`name_${i18n.language}`]}</span>
                    <span className="cursor-pointer" onClick={rolePopupHandler}>
                      <FaRedo />
                    </span>
                  </li>
                );
              })}
              {Array.from(Array(3 - selectedRoles.length).keys()).map(({}, i) => (
                <li
                  key={i}
                  className="cursor-pointer bg-grey-100 mb-3 rounded-full px-4 py-3 text-lg text-black font-bold flex items-center justify-between"
                  onClick={rolePopupHandler}>
                  {getTranslation(t, `strengthsRoles.roleChoice`, translationPrefix)}
                  <DownArrowIcon />
                </li>
              ))}
            </ul>
          </div>
          {errorMessage && (
            <div className="my-2 text-center">
              <p className="text-red-500">{errorMessage}</p>
            </div>
          )}
        </section>
        {showStrengthsPopup && (
          <Popups.OptionSelectorPopup
            handler={setShowStrengthsPopup}
            listItems={strengths}
            listType={'strengths'}
            addListItem={addListItem}
            removeListItem={removeListItem}
            selectedItems={selectedStrengths}
            orgPrefix={translationPrefix}
          />
        )}
        {showRolesPopup && (
          <Popups.OptionSelectorPopup
            handler={setShowRolesPopup}
            listItems={roles}
            listType={'roles'}
            addListItem={addListItem}
            removeListItem={removeListItem}
            selectedItems={selectedRoles}
            orgPrefix={translationPrefix}
          />
        )}
      </Popups.FullPagePopup>

      {loading && <Loader transparent={true} />}
    </>
  );
}
