import { isEmpty } from 'lodash';

export const checkRequiredFieldsInformationForm = (profile) => {
  const requiredFields = profile?.profileTypeData?.inputFields
    .filter((field) => field.required)
    .map((field) => field?.field?.key);

  let missingFields = [];

  requiredFields.forEach((field) => {
    if (field === 'trainingAndDiploma') {
      if (isEmpty(profile['trainingAndDiploma1'])) missingFields.push(field);
    } else if (field === 'recentProfession') {
      if (isEmpty(profile['recentProfession1'])) missingFields.push(field);
    } else if (profile[field] === '' || profile[field] === null || !profile[field]) {
      missingFields.push(field);
    }
  });

  return missingFields;
};

export const CheckProfileProgress = (profile) => {
  const missingFields = checkRequiredFieldsInformationForm(profile);
  let progress = 0;
  if (missingFields?.length === 0) progress++;
  if (!isEmpty(profile['image'])) progress++;
  if (profile?.['strengths']?.length === 3 && profile?.['roles']?.length === 3) progress++;

  const videoFunctionEnabled = profile?.profileTypeData?.hasVideoFunction;

  const totalVideosRequired = !videoFunctionEnabled
    ? 0
    : profile?.profileTypeData?.videosInformation?.filter((video) => video.isActive).length;
  const totalVideosUploaded = profile?.videos?.videos?.filter((video) => video.videoURL).length;

  if (videoFunctionEnabled && !isNaN(totalVideosUploaded)) progress += totalVideosUploaded;

  return { totalSteps: 3 + totalVideosRequired, progress };
};

export const CheckIfProfileIsComplete = (profile) => {
  const missingFields = checkRequiredFieldsInformationForm(profile);
  if (missingFields?.length > 0) return false;
  if (isEmpty(profile['image'])) return false;
  if (profile?.['strengths']?.length < 3) return false;
  if (profile?.['roles']?.length < 3) return false;

  const videoFunctionEnabled = profile?.profileTypeData?.hasVideoFunction;

  const totalVideosRequired = profile?.profileTypeData?.videosInformation?.filter(
    (video) => video.isActive
  ).length;
  const totalVideosUploaded = profile?.videos?.videos?.filter((video) => video.videoURL).length;

  if (
    videoFunctionEnabled &&
    totalVideosRequired > 0 &&
    (totalVideosUploaded === undefined || totalVideosRequired > totalVideosUploaded)
  )
    return false;

  return true;
};
