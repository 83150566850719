import React, { useState } from 'react';
import { FaCircle, FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function ListItem({
  data,
  listType,
  addListItem,
  removeListItem,
  selectedItems,
  maxItems
}) {
  const [isEnabled, setIsEnabled] = useState(
    selectedItems.find((item) => item._id === data._id) ? true : false
  );
  const { i18n } = useTranslation();

  const handler = () => {
    if (!isEnabled) {
      if (selectedItems.length < maxItems) {
        addListItem(data, listType);
        setIsEnabled(!isEnabled);
      }
    } else {
      removeListItem(data, listType);
      setIsEnabled(!isEnabled);
    }
  };
  return (
    <li className="list-item my-3" onClick={handler}>
      <div className="flex justify-between items-center">
        <span className="mr-2 text-lg">{data[`name_${i18n.language}`]}</span>
        <div>
          {isEnabled ? (
            <FaCheckCircle className="icon active" size={25} />
          ) : (
            <FaCircle className="icon" size={25} />
          )}
        </div>
      </div>
    </li>
  );
}
