import React, { useState, useEffect } from 'react';

import { CheckIcon } from '@heroicons/react/24/outline';

export default function InputRadio({
  label,
  labelClassName,
  labelFirst = false,
  className,
  checked,
  onChange,
  col = false,
  ...props
}) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div
      className={`flex gap-1 items-center justify-between ${col ? 'w-full' : ''}`}
      onClick={() => onChange()}>
      {label && labelFirst && (
        <label className={`text-lg font-bold ${labelClassName}`}>{label}</label>
      )}
      <input hidden type="radio" checked={isChecked} onChange={onChange} {...props} />
      <span className="flex items-center justify-center w-[26px] h-[26px] rounded-lg bg-grey-100">
        {isChecked && <CheckIcon color="#545454" width={22} />}
      </span>
      {label && !labelFirst && (
        <label className={`text-base font-bold ${col ? 'w-[90%]' : ''}`}>{label}</label>
      )}
    </div>
  );
}
